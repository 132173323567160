<template>
  <div>
    <!-- image retriever -->
    <section class="flex justify-center w-full container">
      <label for="upload-trigger" class="button --primary -mt-2">
        <fa-icon icon="upload"></fa-icon>
        <span class="ml-2 uppercase tracking-wider">{{ text }}</span>
      </label>
      <input
        type="file"
        id="upload-trigger"
        name="image"
        accept=".jpg, .jpeg"
        hidden
        @change="setImage"
      />
    </section>

    <!-- cropper container -->
    <div
      v-if="imageSrc"
      class="w-full h-screen text-xl bg-gray-800 bg-opacity-90 fixed z-30 top-0 left-0 pt-12 p-4"
    >
      <section>
        <p class="uppercase tracking-wide mb-2 text-white text-center">
          Atur gambar
        </p>
        <vue-cropper
          class="max-w-xl mx-auto container"
          ref="cropper"
          :aspect-ratio="ratio"
          :guides="true"
          :src="imageSrc"
          alt=" "
        ></vue-cropper>

        <div class="flex justify-center mt-4">
          <button class="button --primary" v-if="imageSrc" @click="uploadImage">
            <fa-icon icon="check"></fa-icon>
            <span class="ml-2">OK</span>
          </button>
          <button class="ml-2 px-4 py-2 text-white" @click="resetSrc">
            Batal
          </button>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import VueCropper from "vue-cropperjs";
import "cropperjs/dist/cropper.css";

export default {
  data() {
    return {
      imageSrc: null,
      // croppedImageSrc: null,
    };
  },
  props: {
    ratio: {
      type: Number,
      default: 16 / 9,
    },
    text: {
      type: String,
      default: "submit",
    },
  },
  methods: {
    // credit to: https://chafikgharbi.com/image-crop-upload/
    setImage(e) {
      const file = e.target.files[0];
      if (!file.type.includes("image/jpeg")) {
        this.$toasted.warning("Gambar harus berupa jpeg / jpg");
        return;
      }
      let reader = new FileReader();
      reader.onload = (event) => {
        this.imageSrc = event.target.result;
        // Rebuild cropperjs with the updated source
        this.$nextTick(function () {
          this.$refs.cropper.replace(event.target.result);
        });
      };
      reader.readAsDataURL(file);
      reader = null;
    },

    // cropImage() {
    // Get image data for post processing, e.g. upload or setting image src
    // this.croppedImageSrc = this.$refs.cropper.getCroppedCanvas().toDataURL();
    // },

    uploadImage() {
      this.$refs.cropper.getCroppedCanvas().toBlob((blob) => {
        this.$emit("ready", blob);
      }, "image/jpeg");
      this.resetSrc();
    },

    resetSrc() {
      this.imageSrc = null;
    },
  },
  mounted() {},
  components: { VueCropper },
  name: "ImageUploader",
};
</script>